import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "info-panel cookies-info-box"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = { class: "d-grid d-sm-block" };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
            (!_ctx.cookiesClosed)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "default", {
                        accept: _ctx.accept,
                        close: _ctx.close
                    }, () => [
                        _createElementVNode("span", {
                            class: "d-block h3",
                            innerHTML: _ctx.title
                        }, null, 8, _hoisted_2),
                        _createElementVNode("p", {
                            class: "mb-1",
                            innerHTML: _ctx.text
                        }, null, 8, _hoisted_3),
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("button", {
                                type: "button",
                                class: "btn btn-secondary me-sm-half mb-half mb-sm-0",
                                onClick: _cache[0] || (_cache[0] =
                                    //@ts-ignore
                                    (...args) => (_ctx.close && _ctx.close(...args))),
                                innerHTML: _ctx.closeLabel
                            }, null, 8, _hoisted_5),
                            _createElementVNode("button", {
                                type: "button",
                                class: "btn btn-primary",
                                onClick: _cache[1] || (_cache[1] =
                                    //@ts-ignore
                                    (...args) => (_ctx.accept && _ctx.accept(...args))),
                                innerHTML: _ctx.acceptLabel
                            }, null, 8, _hoisted_6)
                        ])
                    ])
                ]))
                : _createCommentVNode("", true)
        ]),
        _: 3
    }));
}
