import "../Scss/style.scss";

import { createApp, defineAsyncComponent } from 'vue';
import { Component } from '@vue/runtime-core';

import CookiesInfoBox from "./Components/CookiesInfoBox.vue";
import CustomPageHeader from "./Components/CustomPageHeader.vue";

import { rootComponent } from "@digitalwerk/frontend-muster";
import merge from 'lodash/merge';

const AjaxForm = defineAsyncComponent(() => import('./Components/AjaxForm.vue'));
const NewsList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/News/List.vue'));
const EventsList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Events/List.vue'));
const NewsMarketList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/News/MarketList.vue'));
const ProjectList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Project/List.vue'));
const GlossaryList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Glossary/List.vue'));
const InfographicList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Infographic/List.vue'));
const InfographicTeaser = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Infographic/Teaser.vue'));
const DocumentHub = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Document/Hub.vue'));
const JobList = defineAsyncComponent(() => import('./../../../../dw_page_types/Resources/Private/Vue/Job/List.vue'));
const Gallery = defineAsyncComponent(() => import('./Components/CustomGallery.vue'));
const StackCards = defineAsyncComponent(() => import('./Components/StackCards.vue'));
const RoadmapSlider = defineAsyncComponent(() => import('./Components/RoadmapSlider.vue'));
const QuoteSlider = defineAsyncComponent(() => import('./Components/QuoteSlider.vue'));
const InfoBlockSlider = defineAsyncComponent(() => import('./Components/InfoBlockSlider.vue'));
const HorizontalScroll = defineAsyncComponent(() => import('./Components/HorizontalScroll.vue'));
const SolrSearchResults = defineAsyncComponent(() => import('./Components/SolrSearchResults.vue'));

const CategoryTabs = defineAsyncComponent(() => import('./Components/CategoryTabs.vue'));
const CategoryTab = defineAsyncComponent(() => import('./Components/CategoryTab.vue'));

const CopyLink = defineAsyncComponent(() => import('./Components/CopyLink.vue'));
const EmergencyNews = defineAsyncComponent(() => import('./Components/EmergencyNews.vue'));
const LiveTicker = defineAsyncComponent(() => import('./Components/LiveTicker.vue'));
const ElectricityConsumption = defineAsyncComponent(() => import('./Components/ElectricityConsumption.vue'));
const ElectricityFrequency = defineAsyncComponent(() => import('./Components/ElectricityFrequency.vue'));
const ElectricityOrigin = defineAsyncComponent(() => import('./Components/ElectricityOrigin.vue'));
const InteractiveVideo = defineAsyncComponent(() => import('./Components/InteractiveVideo.vue'));
const ProjectTabsSticky = defineAsyncComponent(() => import('./Components/ProjectTabsSticky.vue'));
const MembershipsMasonry = defineAsyncComponent(() => import('./Components/MembershipsMasonry.vue'));
const BarometerCircle = defineAsyncComponent(() => import('./Components/BarometerCircle.vue'));
const BarometerDetails = defineAsyncComponent(() => import('./Components/BarometerDetails.vue'));
const BookingForm = defineAsyncComponent(() => import('./Components/BookingForm.vue'));

const ElConfigProvider = defineAsyncComponent(() => import('element-plus/lib/components/config-provider'));
const ElScrollbar = defineAsyncComponent(() => import('element-plus/lib/components/scrollbar'));
const ElPopover = defineAsyncComponent(() => import('element-plus/lib/components/popover'));
const ElTooltip = defineAsyncComponent(() => import('element-plus/lib/components/tooltip'));
const ElDatePicker = defineAsyncComponent(() => import('element-plus/lib/components/date-picker'));

// // import * as Sentry from "@sentry/vue";
//
// //TODO: Vue Sentry, DNS must be https
// // Sentry.init({
// //     Vue: Vue,
// //     dsn: "http://4d7eadae930d4b6eae70a46050ff6f4e@195.2.221.3:9000/5",
// // });
// // Vue.config.errorHandler = function (err, vm, info) {
// //     Sentry.captureMessage(err.toString())
// // }
// // Vue.config.warnHandler = function (msg, vm, trace) {
// //     Sentry.captureMessage(msg.toString())
// // }

const PREVENT_UNLOAD_CLASSES = [
    '.ajax',
    '.download',
    '#scroll-to-top',
    '[data-photoswipe]',
    '[download]',
    '[href^=\\#]',
    '[href*=ajax]',
    '[href^=javascript]',
    '[href^=mailto]',
    '[href^=tel]',
    '[href*=tx_typoscriptrendering]',
    '[target^=_blank]',
    '.plotly a',
    '.ce-graph a',
];

const MQ_DESKTOP = window.matchMedia("(min-width:992px)");

const projectRootComponent:Component = {
    components: {
        'cookies-info-box': CookiesInfoBox,
        'custom-page-header': CustomPageHeader,
        'ajax-form': AjaxForm,
        NewsList,
        EventsList,
        'news-market-list': NewsMarketList,
        'project-list': ProjectList,
        'glossary-list': GlossaryList,
        'infographic-list': InfographicList,
        InfographicTeaser,
        'document-hub': DocumentHub,
        JobList,
        gallery: Gallery,
        'stack-cards': StackCards,
        'roadmap-slider': RoadmapSlider,
        'quote-slider': QuoteSlider,
        'info-block-slider': InfoBlockSlider,
        'horizontal-scroll': HorizontalScroll,
        'category-tabs': CategoryTabs,
        'category-tab': CategoryTab,
        'copy-link': CopyLink,
        'solr-search-results': SolrSearchResults,
        'emergency-news': EmergencyNews,
        'live-ticker': LiveTicker,
        'electricity-consumption': ElectricityConsumption,
        'electricity-frequency': ElectricityFrequency,
        'electricity-origin': ElectricityOrigin,
        'interactive-video': InteractiveVideo,
        'project-tabs-sticky': ProjectTabsSticky,
        'memberships-masonry': MembershipsMasonry,
        BarometerCircle,
        BarometerDetails,
        BookingForm,

        ElConfigProvider,
        'el-scrollbar': ElScrollbar,
        'el-popover': ElPopover,
        ElTooltip,
        ElDatePicker,
    },
    data() {
        return {
            windowInnerHeight: window.innerHeight,
            windowViewportHeight: document.documentElement.clientHeight, // without address bar for mobile browsers
            footerNewsletterOffset: 0,
            emergencyNewsHeight: {value: 0},
            jobListInViewportCnt: 0,
            isMobile: !MQ_DESKTOP.matches,
        }
    },
    provide() {
        return {
            lazyLoad: this.lazyLoad,
            scrollOffset: this.scrollOffset,
            emergencyNewsHeight: this.emergencyNewsHeight,
            observeJobList: this.observeJobList,
        }
    },
    watch: {
        windowInnerHeight: {
            immediate: true,
            handler(newWindowInnerHeight) {
                document.documentElement.style.setProperty('--vh', `${newWindowInnerHeight * 0.01}px`);
            },
        },
        windowViewportHeight: {
            immediate: true,
            handler(newWindowViewportHeight) {
                document.documentElement.style.setProperty('--vh-document', `${newWindowViewportHeight * 0.01}px`);
            },
        },
        footerNewsletterOffset(newFooterNewsletterOffset, oldFooterNewsletterOffset) {
            document.documentElement.style.setProperty('--footer-newsletter-offset', newFooterNewsletterOffset + 'px');
        },
        emergencyNewsHeight: {
            deep: true,
            handler(newEmergencyNewsHeight, oldEmergencyNewsHeight) {
                document.documentElement.style.setProperty('--emergency-news-height', newEmergencyNewsHeight.value + 'px');
            },
        },
    },
    methods: {
        mountedHook() {
            window.addEventListener('load', this.updateFooterNewsletterOffset);
            window.addEventListener('resize', () => {
                this.setWindowInnerHeight();
                this.updateFooterNewsletterOffset();
            });
            this.setWindowInnerHeight();
            this.updateFooterNewsletterOffset();
            MQ_DESKTOP.addEventListener('change', () => {
                this.isMobile = !MQ_DESKTOP.matches;
            });
        },
        setWindowInnerHeight() {
            this.windowInnerHeight = window.innerHeight;
            this.windowViewportHeight = document.documentElement.clientHeight;
        },
        updateFooterNewsletterOffset() {
            if (this.$refs.footerNewsletter) {
                this.footerNewsletterOffset = Math.round(this.$refs.footerNewsletter.offsetHeight * 0.5);
            }
        },
        initUnload() {
            let links = 'a';

            PREVENT_UNLOAD_CLASSES.forEach((className) => {
                links += `:not(${className})`;
            });

            document.querySelectorAll<HTMLAnchorElement>(links).forEach((link) => {
                link.addEventListener('click', (event) => {
                    const target = event.currentTarget as HTMLAnchorElement | null;

                    if (event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1) {
                        return true;
                    } else if (target?.pathname === window.location.pathname) {
                        return true;
                    } else if (target?.getAttribute('id') === 'history-back') {
                        event.preventDefault();
                        if (window.history.length > 1) {
                            window.history.back();
                        }

                        return false;
                    }
                    document.body.classList.remove('loaded');

                    return true;
                });
            });
        },
    }
};

const app = createApp(
    merge(rootComponent, projectRootComponent)
);

app.component((rootComponent as any).components.CustomPageHeader);

app.mount('#page');
