import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache) {
    return (_openBlock(), _createElementBlock("header", {
        class: _normalizeClass({ 'header--hidden': _ctx.isHidden, 'header--fixed': _ctx.isFixed, 'header--bg-color': _ctx.isBgColor })
    }, [
        _renderSlot(_ctx.$slots, "default", {
            isSearchOpen: _ctx.isSearchOpened,
            toggleSearchForm: _ctx.toggleSearchForm,
            isLangNavOpened: _ctx.isLangNavOpened,
            toggleLangNav: _ctx.toggleLangNav,
            toggleMobileNav: _ctx.toggleMobileNav,
            openSubNav: _ctx.openSubNav,
            closeSubNav: _ctx.closeSubNav,
            parentNavItem: _ctx.parentNavItem
        })
    ], 2));
}
